import React, { useEffect, useState } from 'react'
import { any, string, func } from 'prop-types'

//components
import ConfirmationModal from '@components/ConfirmationModal'

// modules
import Auth from '@modules/auth'
import Router from '@modules/router'
import Layout from '@compositions/Layout'
import Cookie from '@modules/cookie'
import UserRoles from '@modules/userRoles'
import { USER_ROLES, IMPERSONATOR_ROLE } from '@modules/constants'
import Url from '@modules/url'

const ErrorsMap = {
  unauthorized: {
    title: 'Access Denied',
    message: 'Your account has been disabled.'
  }
}

let tmrRedirect = null
let tmrDiscounting = null
const SECONDS_FAILULRE_REDIRECT = 6

const ErrorMessage = ({ error, description, onExit }) => {
  const [time, setTime] = useState(SECONDS_FAILULRE_REDIRECT)
  const failure = ErrorsMap[error] || { title: error, message: description }

  useEffect(() => {
    if (time > 0) {
      tmrDiscounting = setTimeout(() => setTime(time - 1), 1000)
    }
  }, [time])
  return (
    <ConfirmationModal
      headerText={failure.title}
      okText="Exit"
      onOk={onExit}
      cancelText=""
      hideCloseButton
      message={
        <div className="mat-4 mab-4 co-black">
          {failure.message && (
            <div className="pab-2 fosi-2">{failure.message}</div>
          )}
          <div>Redirecting in...{time} seconds</div>
        </div>
      }
      onBackdropClick={() => null}
    />
  )
}

ErrorMessage.propTypes = {
  error: string.isRequired,
  description: string.isRequired,
  onExit: func
}

ErrorMessage.defaultProps = {
  onExit: () => null
}

const Callback = ({ location }) => {
  const [showHeader, setShowHeader] = useState(false)
  const { hash } = location || { search: '' }
  const { error, error_description, state } = hash
    ? Url.parseSearch(hash.substr(1))
    : {}

  const logoutOnFailure = () => {
    clearTimeout(tmrRedirect)
    clearTimeout(tmrDiscounting)
    Router.go('/logout')
  }
  const isUnauthorized = error === 'unauthorized'

  useEffect(() => {
    if (isUnauthorized) {
      setShowHeader(false)
      Cookie.remove(state, {}, () => null)
      tmrRedirect = setTimeout(
        () => Router.go('/logout'),
        SECONDS_FAILULRE_REDIRECT * 1000
      )
    } else {
      Auth.isoHandleAuthentication((_, result) => {
        if (result) {
          UserRoles.hasNeededRoles(
            USER_ROLES,
            [IMPERSONATOR_ROLE],
            (err, isImpersonator) => {
              Cookie.getAsString(result.state, (_, value) => {
                const redirectUrl = isImpersonator
                  ? '/impersonation'
                  : value || ''
                const hasSearch = redirectUrl.toString().indexOf('?') !== -1
                Cookie.remove(result.state, {}, () => null)
                Router.go(
                  `${redirectUrl}${hasSearch ? '&ref=' : '?ref='}success_login`
                )
              })
            },
            true
          )
        }
      })
    }
  }, [isUnauthorized, state])
  return (
    <Layout className="pa-3" showHeader={showHeader}>
      <div>
        {isUnauthorized ? (
          <ErrorMessage
            error={error}
            description={error_description}
            onExit={logoutOnFailure}
          />
        ) : (
          'Redirecting...'
        )}
      </div>
    </Layout>
  )
}

Callback.propTypes = {
  location: any
}

Callback.defaultProps = {
  location: {}
}

export default Callback
